import React from 'react'
import styled from 'styled-components'

import ebayBanner from '../assets/ebayBanner.png'
const EBAY_LINK = 'https://www.ebay.de/usr/bjd-dolls-kruselings'

const EbayLinks = () => {
	return (<EbayBanner>
		<a href={EBAY_LINK} rel="noopener noreferrer" target="_blank">
				<EbayImg src={ebayBanner} />
				<h3>Find products on Ebay Kruselings</h3>
		</a>
	</EbayBanner>)
}

export default EbayLinks


const EbayBanner = styled.div`

margin: auto;
text-align: center;
padding-bottom: 2vh;
border-bottom: 1px solid #d9d9d9;
border-top: 1px solid #d9d9d9;
padding-top: 6vh;
margin-bottom: 7vh;

`


const EbayImg = styled.img`
width: 80%;
text-align: center;
margin-bottom: 7vh;
margin-top: 2vh;

`
