import React from 'react';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components'

import about from '../assets/Sonja-und-Hagen-rev_x350.jpg'

const EBAY_LINK = 'https://www.ebay.de/usr/bjd-dolls-kruselings'

const AboutUs = (props) => {
	return (
		
		<Container>
			<Row>
				<AboutImg src={about} />
			</Row>
			<Row>
				<AboutParagraph>For many years we have been active in the doll world with enthusiasm, love and passion. Sonja has invented, designed and produced countless dolls. She has gone through all aspects of doll-making from the wonderful work on a unique piece to the exhilarating stress of mass production. Hagen's creative imagination influenced the functionality of the dolls and he also inspired ideas for something new and unprecedented. So we grew together as a team.
				</AboutParagraph>
				<AboutParagraph>After all these years we are once again eager to realize unique works of doll art. Away from anonymous production and towards personally designed objects. Again, to work on single pieces.</AboutParagraph>
				<AboutParagraph>At the moment we are taking great pleasure in lovingly designing doll accessories, simply because it is nice to let dolls live in a special environment.On this web page we first of all show two foldable doll rooms that are suitable for “our” Kidz’n’Cats mini dolls (21 cm), the Kruselings dolls (23 cm), Lottis and Barbies.</AboutParagraph>
				<AboutParagraph>We also offer a small selection of Kruselings dolls and outfits on Ebay.<br/>Please visit <a href={EBAY_LINK} target="_blank" rel="noopener noreferrer">our page</a> for more information:<br/>
				<a href={EBAY_LINK} target="_blank" rel="noopener noreferrer">{EBAY_LINK}</a><br/>
				</AboutParagraph>
			
			</Row>
		</Container>
		

	);
}

export default AboutUs;


const AboutImg = styled.img`
width: 70%;
border-radius: 5vh;
margin: auto;
margin-bottom: 2vh;
`

const AboutParagraph = styled.div`
margin-bottom: 2vh;
font-size: 120%;
font-weight: 400;
width: 80%;
margin: auto;
margin-bottom: 1vh;
margin-top: 2vh;

`