import React from 'react';
import {  Container, Row, Col } from 'reactstrap';
import styled from 'styled-components'
import AboutUs from './AboutUs'
import EbayLinks from './EbayLinks'
import DataPrivacy from './DataPrivacy'
import Impressum from './Impressum'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import carousel1 from '../assets/carousel/ball-high_1500x.jpg'
import carousel2 from '../assets/carousel/bett-high_1500x.jpg'
import carousel3 from '../assets/carousel/schlaf-high_1500x.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import p01 from '../assets/products/01.png'
import p02 from '../assets/products/02.png'
import p03 from '../assets/products/03.png'
import p04 from '../assets/products/04.png'
import p05 from '../assets/products/05.png'
import p06 from '../assets/products/06.png'
import p07 from '../assets/products/07.png'
import p08 from '../assets/products/08.png'
import p09 from '../assets/products/09.png'
import p10 from '../assets/products/10.png'
import p11 from '../assets/products/11.png'
import p12 from '../assets/products/12.png'
import p13 from '../assets/products/13.png'
import p14 from '../assets/products/14.png'
import p15 from '../assets/products/15.png'
import p16 from '../assets/products/16.png'
import p17 from '../assets/products/17.png'
import p18 from '../assets/products/18.png'
import p19 from '../assets/products/19.png'
import p20 from '../assets/products/20.png'
import p21 from '../assets/products/21.png'
import p22 from '../assets/products/22.png'
import p23 from '../assets/products/23.png'
import p24 from '../assets/products/24.png'
import p25 from '../assets/products/25.png'
import p26 from '../assets/products/26.png'
import p27 from '../assets/products/27.png'
import p28 from '../assets/products/28.png'
import p29 from '../assets/products/29.png'
import p30 from '../assets/products/30.png'
import p31 from '../assets/products/31.png'
import p32 from '../assets/products/32.png'
import p33 from '../assets/products/33.png'
import p34 from '../assets/products/34.png'






import logo from '../assets/transparentlogo_4x_2cdf22b2-ef4c-4220-b4a7-432de5b1a96f_360x.png'

import { Carousel } from 'react-responsive-carousel'


const LogoImg = styled.img`
max-width: 150px;
`
const Slide = styled.div`
width: 100%
`

const SlideImg = styled.img`
width: 100%;
`

const ProductImage = styled.img`
width: 100%;
border: 1px solid #eee;
margin-bottom: 5vh;
`
const Main = () => {

  

  const EBAY_LINK = 'https://www.ebay.de/usr/bjd-dolls-kruselings'

  const productImages = <>
    <Row>
      <Col><a href={EBAY_LINK} rel="noopener noreferrer" target="_blank" ><ProductImage src={p01} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p02} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p03} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p04} /></a></Col>
    </Row>
    <Row>
      <AboutUs />
    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p05} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p06} /></a></Col>
    </Row>
    <Row>
      <EbayLinks />

    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p07} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p08} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p09} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p10} /></a></Col>
    </Row>
   
    <Row>

      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p11} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p12} /></a></Col>

    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p13} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p14} /></a></Col>

    </Row>
   
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p15} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p16} /></a></Col>

    </Row>
    <Row>
      <EbayLinks />

    </Row>

    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p17} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p18} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p19} /></a></Col>

    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p20} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p21} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p22} /></a></Col>
    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p23} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p24} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p25} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p26} /></a></Col>
    </Row>
    <Row>
    <EbayLinks />

    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p27} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p28} /></a></Col>
    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p29} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p30} /></a></Col>
    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p29} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p30} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p31} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p32} /></a></Col>
    </Row>
    <Row>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p33} /></a></Col>
      <Col><a href={EBAY_LINK} target="_blank" rel="noopener noreferrer"><ProductImage src={p34} /></a></Col>
    </Row></>





  const onChange = () => null
  const onClickItem = () => null
  const onClickThumb = () => null

  return (<>
    <Router>
      <Container>
        <Row>
          <Col xs="3">
            <a href="/" rel="noopener noreferrer">
              <LogoImg src={logo} /></a>
          </Col>
          {/* <Col xs="auto">Photo Journal</Col>*/}
          <Col xs="3">&nbsp;</Col>
        </Row>

        <Switch>
          <Route path="/data">
            <Row>
              <DataPrivacy />
            </Row>
          </Route>
          <Route path="/impressum">
            <Row>
              <Impressum />
            </Row>
          </Route>

          <Route path="/">
            <Row>
              <Carousel
                swipeable={true}
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                onChange={onChange}
                onClickItem={onClickItem}
                onClickThumb={onClickThumb}
                transitionTime={500}
              >
                <Slide><SlideImg src={carousel1} /></Slide>
                <Slide><SlideImg src={carousel2} /></Slide>
                <Slide><SlideImg src={carousel3} /></Slide>
              </Carousel>
            </Row>
            {productImages}
          </Route>
        </Switch>
      </Container>

      <FooterDiv>
        <Container>
          <Row>
            <Col xs="6">
              <FooterText >Contact Us / Kontakt</FooterText><br />
              <p>By phone (+49) (0) 170 547 1977</p>
              <p>Via email: <a href="mailto:sonja@the-hartmann.de"
                rel="noopener noreferrer"
                target="_blank">sonja@the-hartmann.de</a></p>

              <FooterLink href="https://www.instagram.com/kruselings_sonja_hartmann"
                rel="noopener noreferrer"
                target="_blank"
              >Instagram</FooterLink>
            </Col>
            <Col xs="6">
              <Link style={{ color: 'white' }} to="/data">Datenschutzerklärung</Link><br />

              <Link style={{ color: 'white' }} to="/impressum">Impressum / AGB</Link>


            </Col>
          </Row>
          <Row>
            <br />
            <span style={{ color: '#555' }}>&copy; 2020 Sonja Hartmann</span>
            <br />
          </Row>
        </Container>
      </FooterDiv>
    </Router>
  </>

  );

}

export default Main;


const FooterDiv = styled.div`
width: 100%;
  background: black;
  color: white;
  padding: 2vh;
	min-height: 200px;
	font-family: 'Alegreya Sans', sans-serif !important;
    font-weight: 400;
`

const FooterText = styled.p`
color: white;
text-decoration: underline;
`

const FooterLink = styled.a`
color: white;
`

